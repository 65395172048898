import React from 'react'
import { useValidPassword } from '../../hooks/auth/useValidPassword'
import { Email } from './email'
import { Password } from './password'
import { AuthContext } from '../../contexts/authContext'
import AuthFlowStateType from '../../interfaces/auth-flow-state'
//import ThirdPartyAuth from './third-party-auth'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import LoadingButton from '../loading-button'

const SignIn: React.FunctionComponent<{
  setAuthState: (state: AuthFlowStateType) => void
  email: string
  emailIsValid: boolean
  setEmail: (email: string) => void
}> = ({ setAuthState, email, emailIsValid, setEmail }) => {
  const { password, setPassword, passwordIsValid } = useValidPassword(``)
  //const [rememberMe, setRemenberMe] = React.useState(true)
  const [error, setError] = React.useState(``)
  const isInvalid = !emailIsValid || email.length === 0 || !passwordIsValid || password.length === 0
  const authContext = React.useContext(AuthContext)
  const { t } = useTranslation()

  const signInClicked: React.FormEventHandler = async (event) => {
    event.preventDefault()
    if (authContext.isBusy) return

    try {
      await authContext.signIn(email, password)
      authContext.setAuthFlowVisible(false)
    } catch (err) {
      if (err.code === `UserNotConfirmedException`) {
        setAuthState(`verifyEmail`)
      } else {
        setError(err.message)
      }
    }
  }

  return (
    <>
      <div className="auth-modal__hint">
        {t(`Don't have an account?`)}
        {` `}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setAuthState(`signup`)
          }}
        >
          {t(`Create account`)}
        </a>
      </div>
      <form onSubmit={signInClicked}>
        <Email setEmail={setEmail} emailIsValid email={email} />
        <Password label={t(`Password`)} passwordIsValid setPassword={setPassword} />
        <div className="d-flex justify-content-between mt-4">
          <div className="auth-modal__checkbox">
            {/*
            <input
              id="login-remember-checkbox"
              type="checkbox"
              onChange={(event) => setRemenberMe(event.target.checked)}
              checked={rememberMe}
            />
            <label htmlFor="login-remember-checkbox">{t(`Remember me`)}</label>
            */}
          </div>
          <div className="auth-modal__forgot-button">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                setAuthState(`requestCode`)
              }}
            >
              {t(`Forgot password`)}
            </a>
          </div>
        </div>
        {error.length === 0 || <div className="auth-modal__feedback">{error}</div>}

        <LoadingButton
          className="auth-modal__main-button"
          type="submit"
          disabled={isInvalid}
          loading={authContext.isBusy}
        >
          {t(`Login`)}
        </LoadingButton>
      </form>

      {/* TODO <ThirdPartyAuth setAuthState={setAuthState} />*/}
    </>
  )
}

export default SignIn
