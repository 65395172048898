import React from 'react'
import showIcon from '../../images/icon-show.svg'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Password: React.FunctionComponent<{
  label: string
  passwordIsValid: boolean
  setPassword: (_: string) => void
}> = ({ label, passwordIsValid, setPassword }) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const inputId = `password-input-` + label.replace(/[^a-z]/, ``).toLowerCase()
  const { t } = useTranslation()

  return (
    <>
      <div
        className={`auth-modal__field d-flex align-items-center overflow-hidden ${
          passwordIsValid ? `` : `auth-modal__field--invalid`
        }`}
      >
        <div className={`auth-modal__password form-floating`}>
          <input
            id={inputId}
            className="form-control w-100"
            type={showPassword ? `text` : `password`}
            placeholder={label}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(evt.target.value)
            }}
          />
          <label htmlFor={inputId} className="auth-modal__label">
            {label}
          </label>
        </div>

        <div
          className={`auth-modal__show-password cursor-pointer ${showPassword ? `opacity-2` : ``}`}
          onClick={() => setShowPassword(!showPassword)}
        >
          <i className="icomoon icomoon-eye-blocked" />
        </div>
      </div>

      {passwordIsValid || <div className="auth-modal__field-feedback">{t(`password_instructions`)}</div>}
    </>
  )
}
