import React from 'react'
import infoIcon from '../images/icon-info.svg'

export enum AlertVariant {
  Info = `info`,
  Error = `error`,
}

export const Alert: React.FC<{ variant?: AlertVariant }> = ({ children, variant = AlertVariant.Info }) => {
  return (
    <div className={`alert alert--${variant}`}>
      {variant === AlertVariant.Info && <img className="alert__icon" src={infoIcon} />}
      {children}
    </div>
  )
}

export default Alert
