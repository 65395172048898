import React from 'react'
import { useValidCode } from '../../hooks/auth/useValidCode'
import { Code } from './code'
import { AuthContext } from '../../contexts/authContext'
import AuthFlowStateType from '../../interfaces/auth-flow-state'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import LoadingButton from '../loading-button'

const VerifyCode: React.FunctionComponent<{
  setAuthState: (state: AuthFlowStateType) => void
  email: string
}> = ({ setAuthState, email }) => {
  const { code, setCode, codeIsValid } = useValidCode(``)
  const [error, setError] = React.useState(``)
  const isInvalid = !codeIsValid || code.length === 0
  const authContext = React.useContext(AuthContext)
  const { t } = useTranslation()

  const sendClicked: React.FormEventHandler = async (event) => {
    event.preventDefault()
    if (authContext.isBusy) return

    try {
      await authContext.verifyCode(email, code)
      setAuthState(`verifySuccess`)
    } catch (err) {
      setError(t(`Invalid code`))
    }
  }

  return (
    <>
      <div className="auth-modal__hint">{t(`email_code_instructions`)}</div>
      <form onSubmit={sendClicked}>
        <Code codeIsValid={codeIsValid} setCode={setCode} code={code} />

        {error.length === 0 || <div className="auth-modal__feedback">{error}</div>}

        <LoadingButton
          className="auth-modal__main-button"
          type="submit"
          disabled={isInvalid}
          loading={authContext.isBusy}
        >
          {t(`Verify account`)}
        </LoadingButton>
      </form>

      <div className="auth-modal__hint text-center mt-4">
        {t(`Already have an account?`)}
        {` `}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setAuthState(`signin`)
          }}
        >
          {t(`Sign In`)}
        </a>
      </div>
    </>
  )
}

export default VerifyCode
