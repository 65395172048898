import React from 'react'
import * as yup from 'yup'

export const useValidEmail = (
  initialValue: string,
): { email: string; setEmail: (_: string) => void; emailIsValid: boolean } => {
  const [email, setEmail] = React.useState(initialValue)
  const [emailIsValid, setEmailIsValid] = React.useState(true)

  React.useEffect(() => {
    const emailSchema = yup.object().shape({
      email: yup.string().email().required(),
    })

    if (email.length === 0) {
      setEmailIsValid(true)
      return
    }

    const isValid = emailSchema.isValidSync({ email })

    setEmailIsValid(isValid)
  }, [email])

  return { email, setEmail, emailIsValid }
}
