import React from 'react'
import { AuthContext } from '../../contexts/authContext'
import deleteSuccessIcon from '../../images/icon-circle-bin.svg'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'

export const DeleteSuccess: React.FunctionComponent = () => {
  const authContext = React.useContext(AuthContext)
  const { t } = useTranslation()
  const { navigate } = useI18next()

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <img className="mt-3" src={deleteSuccessIcon} width="56px" height="56px" />
        <div className="auth-modal__title mt-4">{t(`Account deleted!`)}</div>
        <div className="auth-modal__hint mt-2 text-center">{t(`account_delete_success`)}</div>
      </div>
      <button
        className="auth-modal__main-button"
        onClick={() => {
          navigate(`/`)
          authContext.setAuthFlowVisible(false)
        }}
      >
        {t(`Okay`)}
      </button>
    </>
  )
}

export default DeleteSuccess
