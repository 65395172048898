import React from 'react'
import OtpInput from 'react-otp-input'

export const Code: React.FunctionComponent<{
  codeIsValid: boolean
  setCode: (_: string) => void
  length?: number
  code: string
}> = ({ codeIsValid, setCode, code, length = 6 }) => {
  return (
    <>
      {/*codeIsValid ? `Code` : `Minimum 6 characters`*/}
      <OtpInput
        value={code}
        onChange={(value: string) => setCode(value)}
        numInputs={length}
        inputStyle="auth-modal__code-digit"
        containerStyle="auth-modal__code"
        hasErrored={!codeIsValid}
        isInputNum
      />
    </>
  )
}

export default Code
