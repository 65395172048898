import React from 'react'
import * as yup from 'yup'

export const useValidPassword = (
  initialValue: string,
): { password: string; setPassword: (_: string) => void; passwordIsValid: boolean } => {
  const [password, setPassword] = React.useState(initialValue)
  const [passwordIsValid, setPasswordIsValid] = React.useState(true)

  React.useEffect(() => {
    const passwordSchema = yup.object().shape({
      password: yup
        .string()
        .min(8)
        // at least 1 lower case letter, 1 upper case letter, 1 number
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/u)
        .required(),
    })

    if (password.length === 0) {
      setPasswordIsValid(true)
      return
    }

    const isValid = passwordSchema.isValidSync({ password })

    setPasswordIsValid(isValid)
  }, [password])

  return { password, setPassword, passwordIsValid }
}
