import React from 'react'
import { useValidPassword } from '../../hooks/auth/useValidPassword'
import { Email } from './email'
import { Password } from './password'
import { AuthContext } from '../../contexts/authContext'
import AuthFlowStateType from '../../interfaces/auth-flow-state'
//import ThirdPartyAuth from './third-party-auth'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import LoadingButton from '../loading-button'

const SignUp: React.FunctionComponent<{
  setAuthState: (state: AuthFlowStateType) => void
  email: string
  emailIsValid: boolean
  setEmail: (email: string) => void
}> = ({ setAuthState, email, setEmail, emailIsValid }) => {
  const { password, setPassword, passwordIsValid } = useValidPassword(``)
  const [error, setError] = React.useState(``)
  const { t } = useTranslation()

  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword(``)

  const isInvalid =
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0 ||
    password !== passwordConfirm

  const authContext = React.useContext(AuthContext)

  const signUpClicked: React.FormEventHandler = async (event) => {
    event.preventDefault()
    if (authContext.isBusy) return

    try {
      await authContext.signUp(email, password)
      setAuthState(`verifyEmail`)
    } catch (err) {
      if ((err as { message?: string }).message) setError((err as { message: string }).message)
      else setError(t(`Something went wrong`))
    }
  }

  return (
    <>
      <div className="auth-modal__hint">
        {t(`Already have an account?`)}
        {` `}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setAuthState(`signin`)
          }}
        >
          {t(`Sign In`)}
        </a>
      </div>
      <form onSubmit={signUpClicked}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} email={email} />
        <Password label={t(`Password`)} passwordIsValid={passwordIsValid} setPassword={setPassword} />
        <Password
          label={t(`Confirm password`)}
          passwordIsValid={passwordConfirmIsValid && passwordConfirm === password}
          setPassword={setPasswordConfirm}
        />

        {error.length === 0 || <div className="auth-modal__feedback">{error}</div>}

        <LoadingButton
          className="auth-modal__main-button"
          type="submit"
          disabled={isInvalid}
          loading={authContext.isBusy}
        >
          {t(`Create account`)}
        </LoadingButton>
      </form>

      {/* TODO <ThirdPartyAuth setAuthState={setAuthState} />*/}
    </>
  )
}

export default SignUp
