import * as React from 'react'
import ReactDOM from 'react-dom'

type ModalProps = {
  visible: boolean
  placement?: 'top' | 'bottom'
  onClose?: () => void
  disableBackdropClose?: boolean
  size?: 'md' | 'lg'
}

const Modal: React.FC<ModalProps> = ({
  visible,
  placement = `top`,
  onClose = () => null,
  disableBackdropClose = false,
  children,
  size = `md`,
}) => {
  React.useEffect(() => {
    visible
      ? document?.body?.classList.add(`zymma-modal__body--open`)
      : document?.body?.classList.remove(`zymma-modal__body--open`)
  }, [visible])

  if (!visible) return null

  return ReactDOM.createPortal(
    <div className={`zymma-modal zymma-modal--${size}`}>
      <div className="zymma-modal__backdrop" onClick={disableBackdropClose ? undefined : onClose} />
      <div className={`zymma-modal__content zymma-modal__content--placement-${placement}`}>{children}</div>
    </div>,
    document.body,
  )
}

export default Modal
