import * as React from 'react'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql, useStaticQuery } from 'gatsby'
import { Page } from '../../../../shared/types/cms'
import LogoZymma from '../images/zymma-logo-neg.inline.svg'
import curvedArrowDownright from '../images/curved-arrow-downright.png'
import useScroll from '../hooks/useScroll'

interface FooterProps {
  className?: string
}

const query = graphql`
  query {
    allStrapiPages {
      edges {
        node {
          id
          title
          slug
          locale
          localizations {
            id
            locale
          }
          navigation {
            footer
            mobile
          }
        }
      }
    }
  }
`

const MenuItem: React.FC<{ title: string; slug: string; separator: boolean }> = ({
  title,
  slug,
  separator = false,
}) => {
  return (
    <>
      {separator && <i className="dot-separator" />}
      <div>
        <Link to={slug}>{title}</Link>
      </div>
    </>
  )
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const { allStrapiPages } = useStaticQuery(query)

  const menuItems = React.useMemo(() => {
    const items: Page[] = []
    allStrapiPages.edges.forEach(({ node }: { node: Page }) => {
      if (node?.navigation?.footer && node?.locale === language) {
        if (node.slug.indexOf(`/`) !== 0) {
          node.slug = `/${node.slug}`
        }
        items.push(node)
      }
    })
    return items
  }, [allStrapiPages])

  const { scrollY } = useScroll()

  return (
    <div className="container mt-auto">
      <div className={`footer d-flex` + className}>
        <div className="footer__zymma">
          <LogoZymma className="footer__zymma-logo" />
          <div className="footer__zymma-text">
            {t(`footer_text_1`)}
            <br />
            <br className="d-lg-none" />
            {t(`footer_text_2`)}
            <br />
            <br className="d-lg-none" />
            {t(`footer_text_3`)}
          </div>
          <div className="footer__zymma-social d-none">
            <i className="fab fa-facebook-f" />
            <i className="fab fa-pinterest-p" />
            <i className="fab fa-linkedin-in" />
          </div>
          <div className="footer__zymma-copyright">© {new Date().getFullYear()} Zymma - all rights reserved</div>
        </div>

        <div className="footer__links">
          <h3 className="footer__links-title">{t(`Quicklinks`)}</h3>
          <div className="footer__links-content">
            {menuItems.map((item, index) => {
              return (
                <MenuItem key={`footer_menu_item_` + index} title={item.title} slug={item.slug} separator={false} />
              )
            })}
          </div>
        </div>

        <div className="footer__info">
          <h3 className="footer__info-title">{t(`Information`)}</h3>
          <div className="footer__info-content">
            Zymma Living GmbH <br />
            Peach Property Group (Deutschland) GmbH <br />
            Aachener Straße 186 <br />
            50931 Köln, Deutschland
          </div>
        </div>
        {scrollY !== null && scrollY > document.body.clientHeight / 2 - window.innerHeight && (
          <div
            className="footer__go-top"
            onClick={() => {
              window.scrollTo(0, 0)
            }}
          >
            {t(`go_top_text`)}
            <img className="footer__go-top-arrow" src={curvedArrowDownright} />
          </div>
        )}
      </div>
    </div>
  )
}
Footer.defaultProps = {
  className: ``,
}
export default Footer
