import * as React from 'react'
import IconMinus from '../images/icon-minus.inline.svg'
import IconPlus from '../images/icon-plus.inline.svg'

interface CollapsibleProps {
  name?: string
  id: string
  title: string
  iconOpen?: string
  iconClose?: string
  additionalClasses?: string[]
  multiple?: boolean
}

const Collapsible: React.FC<CollapsibleProps> = ({
  name = ``,
  id,
  title,
  iconOpen,
  additionalClasses = [],
  iconClose,
  children,
  multiple = false,
}) => {
  if (name === ``) name = `id`

  return (
    <div className={[`collapsible-section`, ...additionalClasses].join(` `)}>
      <input
        type={multiple ? `checkbox` : `radio`}
        name={name}
        id={id}
        className="collapsible-section__checkbox"
        hidden
        defaultChecked={false}
      />
      <label className="collapsible-section__label" htmlFor={id}>
        <span>{title}</span>
        {iconOpen ? (
          <img className="collapsible-section__icon collapsible-section__icon--open" src={iconOpen} />
        ) : (
          <IconPlus className="collapsible-section__icon collapsible-section__icon--open" />
        )}
        {iconClose ? (
          <img src={iconClose} className="collapsible-section__icon collapsible-section__icon--close" />
        ) : (
          <IconMinus className="collapsible-section__icon collapsible-section__icon--close" />
        )}
      </label>
      <div className="collapsible-section__content">{children}</div>
    </div>
  )
}

export default Collapsible
