import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Email: React.FunctionComponent<{
  emailIsValid: boolean
  setEmail: (_: string) => void
  email?: string
}> = ({ emailIsValid, setEmail, email = undefined }) => {
  const [hasChanged, setHasChanged] = React.useState(false)
  const isValid = emailIsValid || !hasChanged
  const { t } = useTranslation()

  return (
    <>
      <div className={`auth-modal__field form-floating ${isValid ? `` : `auth-modal__field--invalid`}`}>
        <input
          id="email-input"
          className="form-control w-100"
          type="text"
          value={email}
          placeholder={t(`Email`)}
          onChange={(event) => {
            setHasChanged(true)
            setEmail(event.target.value.toLowerCase())
          }}
        />
        <label htmlFor="email-input" className="auth-modal__label">
          {t(`Email`)}
        </label>
      </div>
      {isValid || <div className="auth-modal__field-feedback">{t(`Invalid email`)}</div>}
    </>
  )
}
