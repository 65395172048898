import React from 'react'
import SignIn from './auth/signin'
import SignUp from './auth/signup'
import Verify from './auth/verify'
import RequestCode from './auth/request-code'
import ForgotPassword from './auth/forgot-password'
import ChangePassword from './auth/change-password'
import Success from './auth/success'
import DeleteAccount from './auth/delete-account'
import DeleteSuccess from './auth/delete-success'
import ChevronLeft from '../images/chevron-left.inline.svg'
import { AuthContext, AuthStatus } from '../contexts/authContext'
import { useValidEmail } from '../hooks/auth/useValidEmail'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import Modal from './modal'
import AuthFlowStateType from '../interfaces/auth-flow-state'

const modalTitles: Record<AuthFlowStateType, string> = {
  signin: `Login`,
  signup: `Create account`,
  verifyEmail: `Verify email`,
  verifySuccess: ``,
  requestCode: `Forgot password`,
  forgotPassword: `Forgot password`,
  changePassword: `Change password`,
  passwordResetSuccess: ``,
  passwordChangeSuccess: ``,
  deleteAccount: `Delete account`,
  deleteAccountSuccess: ``,
}

export const AuthModal: React.FC = () => {
  const { email, setEmail, emailIsValid } = useValidEmail(``)
  const {
    authStatus,
    authFlowVisible,
    setAuthFlowVisible,
    authFlowState: state,
    setAuthFlowState: setState,
    attrInfo,
  } = React.useContext(AuthContext)
  const { t } = useTranslation()
  const { navigate } = useI18next()

  React.useEffect(() => {
    // Reset auth flow state when the modal is hidden
    if (!authFlowVisible && state !== `signin`) setState(`signin`)
  }, [authFlowVisible, state])

  // If the user is connected pull its email from the session, used for password change
  React.useEffect(() => {
    if (authStatus === AuthStatus.SignedIn) {
      const emailAttr = attrInfo.find((attr) => attr.Name === `email`)
      setEmail(emailAttr?.Value ?? ``)
    } else {
      setEmail(``)
    }
  }, [authStatus, attrInfo])

  const showBackIcon = !(
    [
      `signin`,
      `verifySuccess`,
      `passwordResetSuccess`,
      `passwordChangeSuccess`,
      `changePassword`,
      `deleteAccount`,
      `deleteAccountSuccess`,
    ].includes(state) ||
    (state === `forgotPassword` && authStatus === AuthStatus.SignedIn)
  )

  return (
    <Modal
      visible={authFlowVisible}
      onClose={() => {
        if (state === `deleteAccountSuccess`) {
          navigate(`/`)
        }
        setAuthFlowVisible(false)
      }}
      placement="bottom"
      size={state === `deleteAccount` ? `lg` : undefined}
    >
      <div className="auth-modal">
        <div className="auth-modal__content">
          <div className="container px-md-0 flex-column">
            <div className="d-flex justify-content-between flex-grow-1">
              <div className="auth-modal__title">
                {showBackIcon && (
                  <div
                    className="auth-modal__return cursor-pointer"
                    onClick={() => {
                      if (state === `signup`) {
                        setState(`signin`)
                      } else if (state === `verifyEmail`) {
                        setState(`signup`)
                      } else if (state === `requestCode`) {
                        setState(`signin`)
                      } else if (state === `forgotPassword`) {
                        setState(`requestCode`)
                      }
                    }}
                  >
                    <ChevronLeft className="opacity-2 " />
                  </div>
                )}
                {modalTitles[state]?.length ? t(modalTitles[state]) : null}
              </div>
              <div
                className="auth-modal__close cursor-pointer"
                onClick={() => {
                  setAuthFlowVisible(false)
                }}
              >
                <i className="icomoon icomoon-cross" />
              </div>
            </div>
            {state === `signin` ? (
              <SignIn setAuthState={setState} email={email} setEmail={setEmail} emailIsValid={emailIsValid} />
            ) : state === `signup` ? (
              <SignUp setAuthState={setState} email={email} setEmail={setEmail} emailIsValid={emailIsValid} />
            ) : state === `verifyEmail` ? (
              <Verify setAuthState={setState} email={email} />
            ) : state === `requestCode` ? (
              <RequestCode setAuthState={setState} email={email} setEmail={setEmail} emailIsValid={emailIsValid} />
            ) : state === `forgotPassword` ? (
              <ForgotPassword setAuthState={setState} email={email} />
            ) : state === `changePassword` ? (
              <ChangePassword setAuthState={setState} email={email} />
            ) : state === `deleteAccount` ? (
              <DeleteAccount />
            ) : state === `deleteAccountSuccess` ? (
              <DeleteSuccess />
            ) : [`verifySuccess`, `passwordChangeSuccess`, `passwordResetSuccess`].includes(state) ? (
              <Success setAuthState={setState} authState={state} />
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default AuthModal
