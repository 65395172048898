import * as React from 'react'
import AuthProvider from '../../contexts/authContext'
import AuthModal from '../../components/auth-modal'
import Bugsnag from '@bugsnag/js'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ErrorBoundary = Bugsnag.getPlugin(`react`)?.createErrorBoundary(React) || React.Fragment

const PageWrapper: React.FC = ({ children }) => {
  const { t } = useTranslation()

  return (
    <ErrorBoundary FallbackComponent={() => <h1>{t(`Something went wrong`)}</h1>}>
      <AuthProvider>
        <AuthModal />
        {children}
      </AuthProvider>
    </ErrorBoundary>
  )
}

export default PageWrapper
