import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import ChevronUp from '../../images/chevron-up.svg'
import ChevronDown from '../../images/chevron-down.svg'
import { AuthContext } from '../../contexts/authContext'
import Collapsible from '../collapsible'
import { CmsTenant } from '../../services/cms'
import { Alert, AlertVariant } from '../alert'
import { Cognito } from '../../services/cognito'
import LoadingButton from '../loading-button'

export const deleteKeyword = `zymma`

export const DeleteAccount: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const [keyword, setKeyword] = React.useState(``)
  const [hasChanged, setHasChanged] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(``)
  const [isLoading, setIsLoading] = React.useState(false)
  const isValid = deleteKeyword === keyword

  const { setAuthFlowVisible, setAuthFlowState } = React.useContext(AuthContext)

  return (
    <>
      <div className="auth-modal__delete-warning">
        <i className="icomoon icomoon-warning auth-modal__delete-warning-icon" />
        <div className="auth-modal__delete-info">{t(`You are about to permanently delete your account`)}.</div>

        <div className="auth-modal__delete-details d-none d-lg-block">{t(`account_delete_disclaimer`)}</div>

        <div className="auth-modal__delete-details--mobile d-block d-lg-none">
          <Collapsible
            id={`auth-modal-confirm-details`}
            title={t(`Details`)}
            iconClose={ChevronUp}
            iconOpen={ChevronDown}
          >
            <div className="auth-modal__delete-details">{t(`account_delete_disclaimer`)}</div>
          </Collapsible>
        </div>
      </div>
      <div className="auth-modal__delete-confirm">
        <div className="auth-modal__delete-confirm-instruction">{t(`account_delete_instructions`)}:</div>
        <div className="auth-modal__delete-confirm-keyword">{deleteKeyword}</div>
      </div>
      <div className={`auth-modal__field form-floating ${!hasChanged || isValid ? `` : `auth-modal__field--invalid`}`}>
        <input
          id="keyword-input"
          className="form-control w-100"
          type="text"
          value={keyword}
          placeholder={t(`Enter keyword`)}
          onChange={(event) => {
            if (event.target.value !== ``) setHasChanged(true)
            setKeyword(event.target.value)
          }}
        />
        <label htmlFor="keyword-input" className="auth-modal__label">
          {t(`Enter keyword`)}
        </label>
      </div>
      {errorMessage && <Alert variant={AlertVariant.Error}>{errorMessage}</Alert>}
      <div className="auth-modal__delete-buttons">
        <button className="auth-modal__delete-buttons-cancel" onClick={() => setAuthFlowVisible(false)}>
          <span className="d-none d-lg-inline">{t(`Cancel, keep account`)}</span>
          <span className="d-inline d-lg-none">{t(`Cancel`)}</span>
        </button>
        <div className="auth-modal__delete-buttons-spacer" />
        <LoadingButton
          className={`auth-modal__delete-buttons-confirm ${
            isValid ? `` : `auth-modal__delete-buttons-confirm--disabled`
          }`}
          onClick={() => {
            if (!isValid || isLoading) return
            setIsLoading(true)
            CmsTenant.deleteMe()
              .then(() => {
                setAuthFlowState(`deleteAccountSuccess`)
                Cognito.signOut()
              })
              .catch(() => {
                setErrorMessage(t(`Something went wrong`))
              })
              .finally(() => {
                setIsLoading(false)
              })
          }}
          loading={isLoading}
        >
          {t(`Delete account`)}
        </LoadingButton>
      </div>
    </>
  )
}

export default DeleteAccount
