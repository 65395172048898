import React from 'react'
import { Email } from './email'
import { AuthContext } from '../../contexts/authContext'
import AuthFlowStateType from '../../interfaces/auth-flow-state'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import LoadingButton from '../loading-button'

export const RequestCode: React.FunctionComponent<{
  setAuthState: (state: AuthFlowStateType) => void
  email: string
  emailIsValid: boolean
  setEmail: (email: string) => void
}> = ({ setAuthState, email, emailIsValid, setEmail }) => {
  const [error, setError] = React.useState(``)
  const { t } = useTranslation()

  const isInvalid = !emailIsValid || email.length === 0
  const authContext = React.useContext(AuthContext)
  const resetPassword: React.FormEventHandler = async (event) => {
    event.preventDefault()
    if (authContext.isBusy) return

    try {
      await authContext.sendCode(email)
      setAuthState(`forgotPassword`)
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <>
      <div className="auth-modal__hint">{t(`Please enter your email below`)}</div>
      <form onSubmit={resetPassword}>
        <Email emailIsValid={emailIsValid} setEmail={setEmail} email={email} />
        {error.length === 0 || <div className="auth-modal__feedback">{error}</div>}
        <LoadingButton
          className="auth-modal__main-button"
          disabled={isInvalid}
          type="submit"
          loading={authContext.isBusy}
        >
          {t(`Reset password`)}
        </LoadingButton>
      </form>
    </>
  )
}

export default RequestCode
