import React from 'react'
import { useValidPassword } from '../../hooks/auth/useValidPassword'
import { Password } from './password'
import { AuthContext } from '../../contexts/authContext'
import AuthFlowStateType from '../../interfaces/auth-flow-state'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import LoadingButton from '../loading-button'

export const ChangePassword: React.FunctionComponent<{
  setAuthState: (state: AuthFlowStateType) => void
  email: string
}> = ({ setAuthState }) => {
  const [error, setError] = React.useState(``)
  const { t } = useTranslation()

  const {
    password: passwordOld,
    setPassword: setPasswordOld,
    passwordIsValid: passwordOldIsValid,
  } = useValidPassword(``)
  const { password, setPassword, passwordIsValid } = useValidPassword(``)
  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword(``)

  const isInvalid =
    !passwordOldIsValid ||
    passwordOld.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0 ||
    password !== passwordConfirm
  const authContext = React.useContext(AuthContext)
  const resetPassword: React.FormEventHandler = async (event) => {
    event.preventDefault()
    if (authContext.isBusy) return

    try {
      await authContext.changePassword(passwordOld, password)
      setAuthState(`passwordChangeSuccess`)
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <>
      <div className="auth-modal__hint">{t(`password_change_instructions`)}</div>
      <form onSubmit={resetPassword}>
        <Password label={t(`Old password`)} setPassword={setPasswordOld} passwordIsValid={passwordOldIsValid} />
        <Password label={t(`New password`)} setPassword={setPassword} passwordIsValid={passwordIsValid} />
        <Password
          label={t(`Confirm password`)}
          setPassword={setPasswordConfirm}
          passwordIsValid={passwordConfirmIsValid && passwordConfirm === password}
        />
        {error.length === 0 || <div className="auth-modal__feedback">{error}</div>}
        <LoadingButton
          className="auth-modal__main-button"
          disabled={isInvalid}
          type="submit"
          loading={authContext.isBusy}
        >
          {t(`Change password`)}
        </LoadingButton>
      </form>
    </>
  )
}

export default ChangePassword
