import React from 'react'
import * as yup from 'yup'

export const useValidCode = (
  initialValue: string,
): { code: string; setCode: (_: string) => void; codeIsValid: boolean } => {
  const [code, setCode] = React.useState(initialValue)
  const [codeIsValid, setCodeIsValid] = React.useState(true)

  React.useEffect(() => {
    const codeSchema = yup.object().shape({
      code: yup.string().min(6).required(),
    })

    if (code.length === 0) {
      setCodeIsValid(true)
      return
    }

    const isValid = codeSchema.isValidSync({ code })

    setCodeIsValid(isValid)
  }, [code])

  return { code, setCode, codeIsValid }
}
