import React from 'react'
import { AuthContext } from '../../contexts/authContext'
import AuthFlowStateType from '../../interfaces/auth-flow-state'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const messages: Record<string, string> = {
  verifySuccess: `Your account has been verified`,
  passwordChangeSuccess: `Your password has been changed`,
  passwordResetSuccess: `Your password has been reset`,
}

const buttonText: Record<string, string> = {
  verifySuccess: `Go to login`,
  passwordChangeSuccess: `Okay`,
  passwordResetSuccess: `Got to login`,
}

export const Success: React.FunctionComponent<{
  setAuthState: (_: AuthFlowStateType) => void
  authState: AuthFlowStateType
}> = ({ setAuthState, authState }) => {
  const authContext = React.useContext(AuthContext)
  const { t } = useTranslation()

  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <i className="auth-modal__success-icon icomoon icomoon-checkmark" />
        <div className="auth-modal__title mt-4">{t(`Success!`)}</div>
        <div className="auth-modal__hint mt-2">{t(messages[authState])}</div>
      </div>
      <button
        className="auth-modal__main-button"
        onClick={() => {
          if (authState === `passwordChangeSuccess`) {
            authContext.setAuthFlowVisible(false)
          } else {
            setAuthState(`signin`)
          }
        }}
      >
        {t(buttonText[authState])}
      </button>
    </>
  )
}

export default Success
